import React from "react";
import { Card, CardBody } from "../../card/card";
import { getHighlightColor } from "../../../helpers/currency";

function ResSup({ data }) {
  const highlightColor = getHighlightColor(data);

  const resData = [
    {
      title: "4H",
      level: data.res_4h,
      from: data.res_4h_dist,
      highlight: data.res_4h_highlight,
    },
    {
      title: "8H",
      level: data.res_8h,
      from: data.res_8h_dist,
      highlight: data.res_8h_highlight,
    },
    {
      title: "12H",
      level: data.res_12h,
      from: data.res_12h_dist,
      highlight: data.res_12h_highlight,
    },
    {
      title: "Today",
      level: data.res_today,
      from: data.res_today_dist,
      highlight: data.res_today_highlight,
    },
    {
      title: "Yesterday",
      level: data.res_yesterday,
      from: data.res_yesterday_dist,
      highlight: data.res_yesterday_highlight,
    },
    {
      title: "Weekly",
      level: data.res_weekly,
      from: data.res_weekly_dist,
      highlight: data.res_weekly_highlight,
    },
    {
      title: "Monthly",
      level: data.res_monthly,
      from: data.res_monthly_dist,
      highlight: data.res_monthly_highlight,
    },
  ];
  const supData = [
    {
      title: "4H",
      level: data.supp_4h,
      from: data.supp_4h_dist,
      highlight: data.supp_4h_highlight,
    },
    {
      title: "8H",
      level: data.supp_8h,
      from: data.supp_8h_dist,
      highlight: data.supp_8h_highlight,
    },
    {
      title: "12H",
      level: data.supp_12h,
      from: data.supp_12h_dist,
      highlight: data.supp_12h_highlight,
    },
    {
      title: "Today",
      level: data.supp_today,
      from: data.supp_today_dist,
      highlight: data.supp_today_highlight,
    },
    {
      title: "Yesterday",
      level: data.supp_yesterday,
      from: data.supp_yesterday_dist,
      highlight: data.supp_yesterday_highlight,
    },
    {
      title: "Weekly",
      level: data.supp_weekly,
      from: data.supp_weekly_dist,
      highlight: data.supp_weekly_highlight,
    },
    {
      title: "Monthly",
      level: data.supp_monthly,
      from: data.supp_monthly_dist,
      highlight: data.supp_monthly_highlight,
    },
  ];

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">RESISTANCE & SUPPORT</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th></th>
                <th colSpan={2}>
                  <small>RESISTANCE</small>
                </th>
                <th colSpan={2}>
                  <small>SUPPORT</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {resData && resData.length > 0 ? (
                resData.map((log, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2 " +
                            (log.highlight || supData[index].highlight
                              ? "text-theme"
                              : "text-white text-opacity-30")
                          }
                        ></i>
                        {log.title}
                      </span>
                    </th>
                    <td>
                      <small
                        key={"res.level".concat(log.level)}
                        className="animate-on-update"
                      >
                        {log.level}
                      </small>
                    </td>
                    <td>
                      <span
                        key={"res.from".concat(log.from)}
                        className={
                          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                          (log.highlight
                            ? highlightColor + " text-theme-900"
                            : "bg-white bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {log.from}
                      </span>
                    </td>
                    <td>
                      <small
                        key={"res".concat(index, "level", supData[index].level)}
                        className="animate-on-update"
                      >
                        {supData[index].level}
                      </small>
                    </td>
                    <td>
                      <span
                        key={"res".concat(index, "from", supData[index].from)}
                        className={
                          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                          (supData[index].highlight
                            ? highlightColor + " text-theme-900"
                            : "bg-white bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {supData[index].from}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default ResSup;
