import { getAxios } from "./axios";
import { getTokenHeaders } from "./helper";
import { ENDPOINT_NEWS } from "../config/endpoints";

export function getNews() {
  return getAxios().get(ENDPOINT_NEWS, getTokenHeaders());
}

export function getNewsByCurrencyCode(currencyCode) {
  return getAxios().get(`${ENDPOINT_NEWS}/${currencyCode}`, getTokenHeaders());
}
