import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import createRootReducer from "./reducers";
import rootSaga from "./sagas/root.saga";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loading", "notifications"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const config = () => {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    composeWithDevTools;

  let store = createStore(
    persistedReducer,
    {},
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, history, persistor };
};

export default config;
