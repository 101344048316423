import * as types from "./types";

export const tokenDetails = ({
  access_token,
  expires_at,
  refresh_token,
  expires_in,
}) => ({
  type: types.TOKEN_DETAILS,
  payload: {
    access_token: access_token || null,
    expires_at: expires_at || null,
    refresh_token: refresh_token || null,
    expires_in: expires_in || null,
  },
});

export const userInfo = ({
  id,
  first_name,
  last_name,
  email,
  currencySettings,
}) => ({
  type: types.USER_INFO,
  payload: {
    id: id || null,
    first_name: first_name || null,
    last_name: last_name || null,
    email: email || null,
    currencySettings: currencySettings || [],
  },
});

export const userInfoClear = () => userInfo({});

export const userInfoLoading = () => ({ type: types.USER_INFO_LOADING });

export const loadUserProfile = () => ({ type: types.USER_LOAD_PROFILE });

export const currencySettingsUpdate = (currenciesSettings) => ({
  type: types.CURRENCY_SETTINGS_UPDATE,
  payload: currenciesSettings,
});

export const verifyTokenIsValid = (valid, status) => ({
  type: types.VERIFY_TOKEN_IS_VALID,
  payload: {
    valid: valid || false,
    status: status || "checking",
  },
});

export const initiateLogOutAction = () => ({
  type: types.USER_LOGOUT_INITIATED,
});

export const logOutAction = (isUnauthorized, stopRedirect) => ({
  type: types.USER_LOGOUT,
  payload: {
    isUnauthorized: isUnauthorized || false,
    stopRedirect: stopRedirect || false,
  },
});
