import { put, takeLatest } from "redux-saga/effects";
import * as Types from "../../actions/types";
import {
  ENDPOINT_WEBSOCKET_AUTH,
  ENDPOINT_WEBSOCKET_HOST,
} from "../../../config/endpoints";
import { store } from "../../store";
import { socketConnect as trySocketConnect } from "../../actions/socket";
import Echo from "laravel-echo";
import { currencyUpdate } from "../../actions/currencies";

function* socketConnect(action) {
  try {
    window.Pusher = require("pusher-js");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: "mt1",
      wsHost: ENDPOINT_WEBSOCKET_HOST,
      wssPort: process.env.REACT_APP_WEBSOCKETS_PORT,
      wsPort: process.env.REACT_APP_WEBSOCKETS_PORT,
      disableStats: true,
      forceTLS: true,
      encrypted: true,
      enabledTransports: ["ws", "wss"],
      authEndpoint: ENDPOINT_WEBSOCKET_AUTH,
      auth: {
        headers: {
          Authorization: `Bearer ${store.getState().user.token.access_token}`,
          Accept: "application/json",
        },
      },
    });

    window.Echo.connector.pusher.connection.bind(
      "state_change",
      function (states) {
        if (states.current === "failed") {
          store.dispatch(trySocketConnect(action.payload.id));
        }

        if (states.current === "connected") {
          console.log("connected");
        }
      }
    );

    window.Echo.private("prices").listen("CurrencyUpdate", (e) => {
      store.dispatch(currencyUpdate(e));
    });
  } catch (e) {
    yield put(trySocketConnect(action.payload.id));
  }
}

// eslint-disable-next-line require-yield
function* socketLogout() {
  window.Echo.disconnect();
}

export default function* socketAuthSaga() {
  yield takeLatest(Types.SOCKET_CONNECT, socketConnect);
  yield takeLatest(Types.SOCKET_LOGOUT, socketLogout);
}
