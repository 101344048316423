import axios from "axios";
import { store } from "../store/store";
import { hideSpinner, showSpinner } from "../store/actions/loading";
import { hideNotification } from "../store/actions/notification";
import { refreshAccessToken } from "./user";
import { tokenDetails } from "../store/actions/user";
import { extractRefreshToken } from "./helper";

export const getAxios = (shouldHideNotification = true) => {
  const state = store.getState();

  const customAxios = axios.create();

  customAxios.defaults.headers["X-IntellectFX-Lang"] = state.locale.lang;

  if (shouldHideNotification) {
    store.dispatch(hideNotification());
  }

  customAxios.interceptors.request.use(
    function (config) {
      store.dispatch(showSpinner());
      return config;
    },
    function (error) {
      store.dispatch(showSpinner());
      return Promise.reject(error);
    }
  );

  customAxios.interceptors.response.use(
    function (response) {
      store.dispatch(hideSpinner());
      return response;
    },
    function (error) {
      store.dispatch(hideSpinner());

      return Promise.reject(error);
    }
  );

  customAxios.interceptors.response.use(
    (response) => response,
    (error) => authTokenInterceptor(error, customAxios)
  );

  return customAxios;
};

export const getAxiosWithoutLoading = (shouldHideNotification = true) => {
  const state = store.getState();

  const customAxios = axios.create();

  customAxios.defaults.headers["X-IntellectFX-Lang"] = state.locale.lang;

  if (shouldHideNotification) {
    store.dispatch(hideNotification());
  }

  customAxios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  customAxios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  customAxios.interceptors.response.use(
    (response) => response,
    async (error) => await authTokenInterceptor(error, customAxios)
  );

  return customAxios;
};

const authTokenInterceptor = async function (error, axiosInstance) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = extractRefreshToken();
    // TODO May be improved by redirecting the user in login page if refresh token call fails
    if (refreshToken) {
      const { access_token, refresh_token, expires_at, expires_in } = (
        await refreshAccessToken(refreshToken)
      ).data.data;

      store.dispatch(
        tokenDetails({ access_token, refresh_token, expires_at, expires_in })
      );
      originalRequest.headers["Authorization"] = "Bearer " + access_token;
      return axiosInstance(originalRequest);
    }
  }
  return Promise.reject(error);
};
