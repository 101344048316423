import React, { useEffect } from "react";
import { connect } from "react-redux";
import ADR from "../../components/pages/currency/ADR";
import RSI from "../../components/pages/currency/RSI";
import Pivots from "../../components/pages/currency/Pivots";
import ResSup from "../../components/pages/currency/ResSup";
import Entry from "../../components/pages/currency/Entry";
import News from "../../components/pages/currency/News";
import { getNewsByCurrencyCode } from "../../api/news";
import {
  updateNewsByCurrency,
  updateNewsTimeLeft,
} from "../../store/actions/news";
import { trimUSD } from "../../api/helper";
import { store } from "../../store/store";
import { UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS } from "../../constants";

function Details({ currency, currencyName, news }) {
  const data = currency;
  useEffect(() => {
    const currencyCode = trimUSD(currencyName);

    const interval = setInterval(() => {
      store.dispatch(updateNewsTimeLeft(currencyCode));
    }, UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS * 1000);

    getNewsByCurrencyCode(currencyCode).then((response) => {
      store.dispatch(updateNewsByCurrency(currencyCode, response.data.data));
    });

    return () => clearInterval(interval);
  }, [currencyName]);

  return (
    <div>
      <h1 className="page-header">{currencyName}</h1>

      <div className="row">
        <div className="col-xl-6">
          <ADR data={data} />
        </div>
        <div className="col-xl-6">
          <RSI data={data} />
        </div>
        <div className="col-xl-4">
          <Pivots data={data} />
        </div>
        <div className="col-xl-8">
          <ResSup data={data} />
        </div>
        <div className="col-xl-4">
          <Entry data={data} />
        </div>
        <div className="col-xl-8">
          <News data={news} />
        </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
  currency: state.currencies[ownProps.currencyName],
  news: state.news.newsByCurrency[trimUSD(ownProps.currencyName)],
}))(Details);
