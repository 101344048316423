import { ErrorMessage, Field, Form, Formik } from "formik";
import { LoginSchema } from "../../helpers/validation/schemas";
import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import CustomField from "../../components/form/CustomField";
import { login } from "../../api/user";
import { tokenDetails } from "../../store/actions/user";
import { showErrorNotification } from "../../store/actions/notification";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_DASHBOARD, ROUTE_FORGOT_PASSWORD } from "../../config/routes";
import { isString } from "../../api/helper";

const LoginForm = (props) => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    try {
      const { access_token, expires_at, expires_in, refresh_token } = (
        await login(values)
      ).data.data;
      props.loginUser({ access_token, expires_at, expires_in, refresh_token });
      navigate(ROUTE_DASHBOARD);
    } catch (e) {
      props.showErrorNotification({
        title: props.intl.formatMessage({ id: "login" }),
        message: isString(e.response.data.message)
          ? e.response.data.message
          : props.intl.formatMessage({ id: "error.generic" }),
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.email"
            name="email"
            type="email"
            required={true}
          />
          <div className="mb-3">
            <div className="d-flex">
              <label className="form-label">
                <FormattedMessage id="forms.labels.password" />{" "}
                <span className="text-danger">*</span>
              </label>
              <Link
                to={ROUTE_FORGOT_PASSWORD}
                className="ms-auto text-white text-decoration-none text-opacity-50"
              >
                <FormattedMessage id="links.forgot_password" />?
              </Link>
            </div>
            <Field
              type="password"
              name="password"
              autoComplete="current-password"
              className={`form-control form-control-lg bg-white bg-opacity-5 ${
                formikProps.touched.password && formikProps.errors.password
                  ? "is-invalid"
                  : ""
              }`}
            />
            <ErrorMessage
              component="div"
              name="password"
              className="invalid-feedback"
            />
          </div>
          <div className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="remember_me"
              />
              <label className="form-check-label" htmlFor="remember_me">
                <FormattedMessage id="forms.labels.remember_me" />
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
          >
            <FormattedMessage id="forms.buttons.sign_in" />
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default connect(null, (dispatch) => ({
  showErrorNotification: ({ title, message }) =>
    dispatch(
      showErrorNotification({
        message,
        title,
      })
    ),
  loginUser: ({ access_token, expires_at, expires_in, refresh_token }) =>
    dispatch(
      tokenDetails({ access_token, expires_at, refresh_token, expires_in })
    ),
}))(injectIntl(LoginForm));
