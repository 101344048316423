import React from "react";
import { Card, CardBody } from "../../card/card";
import { getHighlightColor } from "../../../helpers/currency";

function Pivots({ data }) {
  const highlightColor = getHighlightColor(data);

  const pivotsData = [
    {
      title: "R3",
      level: data.pivot_r3,
      from: data.pivot_r3_dist,
      highlight: data.pivot_r3_highlight,
    },
    {
      title: "R2",
      level: data.pivot_r2,
      from: data.pivot_r2_dist,
      highlight: data.pivot_r2_highlight,
    },
    {
      title: "R1",
      level: data.pivot_r1,
      from: data.pivot_r1_dist,
      highlight: data.pivot_r1_highlight,
    },
    {
      title: "CP",
      level: data.pivot_c,
      from: data.pivot_c_dist,
      highlight: data.pivot_c_highlight,
    },
    {
      title: "S1",
      level: data.pivot_s1,
      from: data.pivot_s1_dist,
      highlight: data.pivot_s1_highlight,
    },
    {
      title: "S2",
      level: data.pivot_s2,
      from: data.pivot_s2_dist,
      highlight: data.pivot_s2_highlight,
    },
    {
      title: "S3",
      level: data.pivot_s3,
      from: data.pivot_s3_dist,
      highlight: data.pivot_s3_highlight,
    },
  ];

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">PIVOTS</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th></th>
                <th>
                  <small>LEVEL</small>
                </th>
                <th>
                  <small>FROM</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {pivotsData && pivotsData.length > 0 ? (
                pivotsData.map((log, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2 " +
                            (log.highlight
                              ? "text-theme"
                              : "text-white text-opacity-30")
                          }
                        ></i>
                        {log.title}
                      </span>
                    </th>
                    <td>
                      <small
                        key={"pivot.level".concat(log.level)}
                        className="animate-on-update"
                      >
                        {log.level}
                      </small>
                    </td>
                    <td>
                      <span
                        key={"pivot.from".concat(log.level)}
                        className={
                          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                          (log.highlight
                            ? highlightColor + " text-theme-900"
                            : "bg-white bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {log.from}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default Pivots;
