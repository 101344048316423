import {
  FAILD_AXIOS_CALL,
  SUCCESS_AXIOS_CALL,
  RESET_AXIOS_CALL,
} from "../actions/types";

const initialState = {
  axiosSuccess: false,
  axiosFaild: false,
};

const axiosStatusNotification = function (state = initialState, action) {
  switch (action.type) {
    case FAILD_AXIOS_CALL:
      return {
        axiosFaild: true,
        axiosSuccess: false,
      };
    case SUCCESS_AXIOS_CALL:
      return {
        axiosFaild: false,
        axiosSuccess: true,
      };
    case RESET_AXIOS_CALL:
      return {
        axiosSuccess: false,
        axiosFaild: false,
      };
    default:
      return state;
  }
};

export default axiosStatusNotification;
