import React from "react";
import { Card, CardBody } from "../../card/card";
import { getHighlightColor } from "../../../helpers/currency";

function ADR({ data }) {
  const highlightColor = getHighlightColor(data);

  const adrData = [
    {
      title: "Price",
      level: data.price,
      highlight: false,
    },
    {
      title: "Avg",
      level: data.average_adr,
      highlight: false,
    },
    {
      title: "Current",
      level: data.current_adr,
      highlight: data.current_adr_highlight,
    },
    {
      title: "Plus/minus",
      level: (data.plus_minus_sign === "up" ? "+" : "-") + data.plus_minus,
      highlight: false,
    },
    {
      title: "From High",
      level: data.dist_from_high,
      highlight: data.dist_from_high <= 10,
    },
    {
      title: "From Low",
      level: data.dist_from_low,
      highlight: data.dist_from_low <= 10,
    },
  ];

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">ADR</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th></th>
                <th>
                  <small>LEVEL</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {adrData && adrData.length > 0 ? (
                adrData.map((log, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2 " +
                            (log.highlight
                              ? "text-theme"
                              : "text-white text-opacity-30")
                          }
                        ></i>
                        {log.title}
                      </span>
                    </th>
                    <td>
                      <span
                        key={"adr".concat(log.level)}
                        className={
                          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                          (log.highlight
                            ? highlightColor + " text-theme-900"
                            : "bg-white bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {log.level}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default ADR;
