import * as Yup from "yup";
import * as Validation from "./validation";

export const LoginSchema = Yup.object().shape({
  email: Validation.emailRules,
  password: Validation.passwordRules,
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Validation.emailRules,
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Validation.passwordRules,
  password_confirmation: Validation.passwordConfirmationRules,
});

export const RegisterSchema = Yup.object().shape({
  first_name: Validation.firstNameRules,
  last_name: Validation.lastNameRules,
  email: Validation.emailRules,
  password: Validation.passwordRules,
  password_confirmation: Validation.passwordConfirmationRules,
  terms_and_conditions: Validation.termsAndConditionsRules,
});

export const ProfileDetailsSchema = Yup.object().shape({
  first_name: Validation.firstNameRules,
  last_name: Validation.lastNameRules,
  email: Validation.emailRules,
});

export const ProfileSecuritySchema = Yup.object().shape({
  current_password: Validation.passwordRules,
  password: Validation.passwordRules,
  password_confirmation: Validation.passwordConfirmationRules,
});
