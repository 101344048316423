import React from "react";
import LoginForm from "./LoginForm";
import SinglePageForm, {
  ALTERNATIVE_LINK_REGISTER,
} from "../single-page-form/SinglePageForm";

const Login = () => {
  return (
    <SinglePageForm
      titleId="pages.login.title"
      subtitleId="pages.login.subtitle"
      formComponent={<LoginForm />}
      alternativeLink={ALTERNATIVE_LINK_REGISTER}
    />
  );
};

export default Login;
