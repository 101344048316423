import React from "react";
import { Card, CardBody } from "../../card/card";
import { getHighlightColor } from "../../../helpers/currency";
import { ROUTE_CURRENCY } from "../../../config/routes";
import { Link } from "react-router-dom";

const exclusions = ["reversal", "news"];

function Entry({ data, title }) {
  const FH = data.dist_from_high;
  const FL = data.dist_from_low;
  const entryData = [...data.entry];
  entryData.splice(1, 0, {
    title: "FH",
    level: FH,
    highlight: FH < 10,
  });
  entryData.splice(2, 0, {
    title: "FL",
    level: FL,
    highlight: FL < 10,
  });
  const highlightColor = getHighlightColor(data);

  function renderLevel(level, highlight) {
    if (Array.isArray(level)) {
      if (level.length === 0) {
        return (
          <span
            className="badge d-block rounded-0 pt-5px w-70px bg-white bg-opacity-25"
            style={{ minHeight: "18px" }}
          >
            -
          </span>
        );
      }

      return level.map((entry) => {
        return (
          <span
            key={"entry".concat(entry)}
            className={
              "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
              (highlight
                ? highlightColor + " text-theme-900"
                : "bg-white bg-opacity-25")
            }
            style={{ minHeight: "18px" }}
          >
            {entry}
          </span>
        );
      });
    }

    return (
      <span
        key={"entry".concat(level)}
        className={
          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
          (highlight
            ? highlightColor + " text-theme-900"
            : "bg-white bg-opacity-25")
        }
        style={{ minHeight: "18px" }}
      >
        {level}
      </span>
    );
  }

  function renderTitle(title) {
    if (title) {
      return (
        <Link
          to={`${ROUTE_CURRENCY}/${title}`}
          className="text-decoration-none text-reset flex-grow-1"
        >
          {title}
        </Link>
      );
    }

    return <span className="flex-grow-1">ENTRY REQUIREMENTS</span>;
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">{renderTitle(title)}</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th></th>
                <th>
                  <small>LEVEL</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span className="d-flex align-items-center">
                    <i className="bi bi-circle-fill fs-6px me-2 text-white text-opacity-30"></i>
                    Price
                  </span>
                </th>
                <td>{renderLevel(data.price, false)}</td>
              </tr>
              {entryData && entryData.length > 0 ? (
                entryData
                  .filter(
                    (log) => exclusions.indexOf(log.title.toLowerCase()) === -1
                  )
                  .map((log, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <span className="d-flex align-items-center">
                          <i
                            className={
                              "bi bi-circle-fill fs-6px me-2 " +
                              (log.highlight
                                ? "text-theme"
                                : "text-white text-opacity-30")
                            }
                          ></i>
                          {log.title}
                        </span>
                      </th>
                      <td>{renderLevel(log.level, log.highlight)}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="3">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default Entry;
