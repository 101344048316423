import React from "react";
import { hideNotification } from "../../store/actions/notification";
import { connect } from "react-redux";

function Notification(props) {
  return (
    <div className="toast show mt-2" data-autohide="true">
      <div className="toast-header">
        <i className="far fa-bell text-muted me-2"></i>
        <strong className="me-auto">{props.title}</strong>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          onClick={() => props.hideNotification(props.notificationId)}
        />
      </div>
      <div className="toast-body">{props.message}</div>
    </div>
  );
}

export default connect(null, (dispatch) => ({
  hideNotification: (notificationId) =>
    dispatch(hideNotification(notificationId)),
}))(Notification);
