import React, { Component } from "react";

class Pace extends Component {
  slowingAnimationThreshold = 70;
  transitionSpeedInSeconds = 1;

  state = {
    width: 0,
  };

  componentDidMount() {
    setTimeout(() => this.animate(), 10);
    this.intervalId = setInterval(() => {
      this.animate();
    }, 500 + 200 * Math.random());
  }

  animate = () => {
    let multiplier = 0.2;
    if (this.state.width > this.slowingAnimationThreshold) {
      multiplier = 0.1;
      this.transitionSpeedInSeconds = this.transitionSpeedInSeconds * 5;
    }
    this.setState({
      width: this.state.width + (1 - this.state.width) * multiplier,
    });
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getStyles = () => {
    const styles = {
      main: {
        height: this.props.height || 10,
        backgroundColor: "var(--bs-theme)",
        transition: `width ${this.transitionSpeedInSeconds}s`,
        width: Math.floor(this.state.width * 100) + "%",
      },
      container: {
        position: "absolute",
        zIndex: 9999,
        width: "100%",
      },
    };

    if (this.props.style) {
      styles.main = Object.assign({}, this.props.style, styles.main);
    }

    return styles;
  };

  render() {
    const styles = this.getStyles();

    const { id, className } = this.props;

    return (
      <div style={styles.container}>
        <div {...{ id, className }} style={styles.main} />
      </div>
    );
  }
}

export default Pace;
