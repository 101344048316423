import React from "react";
import { Card, CardBody } from "../../card/card";
import { timeFormat } from "../../../api/helper";
import styles from "./News.module.css";
import moment from "moment";

function News({ data }) {
  function impactColor(impact) {
    switch (impact) {
      case "High":
        return styles.red;
      case "Medium":
        return styles.yellow;
      case "Low":
        return styles.splashedWhite;
      default:
        return "text-white text-opacity-30";
    }
  }

  function isExpired(date) {
    return moment().utc().isAfter(moment.utc(date));
  }

  function isLastMinute(date) {
    return (
      !isExpired(date) &&
      moment().utc().add(2, "minutes").isAfter(moment.utc(date))
    );
  }

  function rowStyle(news) {
    const classes = [];
    if (isExpired(news.time)) classes.push(styles.newsExpired);
    if (isLastMinute(news.time))
      if (news.impact === "High") {
        classes.push(styles.upcomingNewsHigh);
      } else if (news.impact === "Medium") {
        classes.push(styles.upcomingNewsMedium);
      }
    return classes.join(" ");
  }

  function newsImpact(news) {
    switch (news.impact) {
      case "High":
        return styles.impactHigh;
      case "Medium":
        return styles.impactMedium;
      default:
        return styles.impactLow;
    }
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">News</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th>
                  <small>DATE</small>
                </th>
                <th>
                  <small>TIME LEFT</small>
                </th>
                <th>
                  <small>COUNTRY</small>
                </th>
                <th>
                  <small>SYMBOL</small>
                </th>
                <th>
                  <small>IMPACT</small>
                </th>
                <th>
                  <small>EVENT</small>
                </th>
                <th>
                  <small>PREVIOUS</small>
                </th>
                <th>
                  <small>CONSENSUS</small>
                </th>
                <th>
                  <small>ACTUAL</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((news) => (
                  <tr key={news.id} className={rowStyle(news)}>
                    <th scope="row">
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2 " +
                            impactColor(news.impact)
                          }
                        ></i>
                        {timeFormat(news.time)}
                      </span>
                    </th>
                    <td>{news.time_left}</td>
                    <td>{news.country}</td>
                    <td>{news.currency_code}</td>
                    <td>
                      <div className={newsImpact(news)}>{news.impact}</div>
                    </td>
                    <td>{news.title}</td>
                    <td>{news.previous}</td>
                    <td>{news.consensus}</td>
                    <td>{news.actual}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default News;
