import React from "react";
import { connect } from "react-redux";
import {
  getDashboardPivots,
  getDashboardResistance,
  getDashboardRSI,
  getDashboardSupport,
} from "../../helpers/currency";
import { Link } from "react-router-dom";
import { ROUTE_CURRENCY } from "../../config/routes";

function CurrencyRow(props) {
  if (!props.name) {
    return <></>;
  }

  const data = props.currency;
  const pivots = getDashboardPivots(data);
  const resistance = getDashboardResistance(data);
  const support = getDashboardSupport(data);
  const rsi = getDashboardRSI(data);
  const rev = data.entry ? data.entry[5] : { level: [] };

  const hAdrColor =
    data.dist_from_high <= 10
      ? "bg-warning"
      : data.dist_from_low <= 10
      ? "bg-green"
      : "bg-yellow";

  return (
    <tr>
      <th scope="row">
        <span className="d-flex align-items-center">
          <i
            className={
              "bi bi-circle-fill fs-6px me-2 " +
              (data.current_adr_highlight
                ? "text-theme"
                : "text-white text-opacity-30")
            }
          ></i>
          <Link
            to={`${ROUTE_CURRENCY}/${props.name}`}
            className="text-decoration-none text-reset"
          >
            {props.name}
          </Link>
        </span>
        <span
          key={"price".concat(data.price)}
          className="animate-on-update"
          style={{ marginLeft: "1rem" }}
        >
          {data.price}
        </span>
      </th>
      <td style={{ height: "7.1rem" }}>
        <span
          key={"adr".concat(data.current_adr, data.average_adr)}
          className={
            "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
            (data.current_adr_highlight
              ? hAdrColor + " text-theme-900"
              : "bg-white bg-opacity-25")
          }
          style={{ minHeight: "18px" }}
        >
          {data.current_adr} / {data.average_adr}
        </span>
        <span
          key={"fh".concat(data.dist_from_high)}
          className={
            "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
            (data.current_adr_highlight && data.dist_from_high <= 10
              ? hAdrColor + " text-theme-900"
              : "bg-white bg-opacity-25")
          }
          style={{ minHeight: "18px" }}
        >
          FH: {data.dist_from_high}
        </span>
        <span
          key={"fl".concat(data.dist_from_low)}
          className={
            "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
            (data.current_adr_highlight && data.dist_from_low < 10
              ? hAdrColor + " text-theme-900"
              : "bg-white bg-opacity-25")
          }
          style={{ minHeight: "18px" }}
        >
          FL: {data.dist_from_low}
        </span>
      </td>
      <td>
        {rsi.length > 0 &&
          rsi.map((entry) => (
            <span
              key={"rsi".concat(entry)}
              className={
                "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                hAdrColor +
                " text-theme-900"
              }
            >
              {entry}
            </span>
          ))}
      </td>
      <td>
        {pivots.length > 0 &&
          pivots.map((entry) => (
            <span
              key={"pivot".concat(entry)}
              className={
                "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                hAdrColor +
                " text-theme-900"
              }
            >
              {entry}
            </span>
          ))}
      </td>
      <td>
        {resistance.length > 0 &&
          resistance.map((entry) => (
            <span
              key={"resistance".concat(entry)}
              className={
                "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                hAdrColor +
                " text-theme-900"
              }
            >
              {entry}
            </span>
          ))}
      </td>
      <td>
        {support.length > 0 &&
          support.map((entry) => (
            <span
              key={"support".concat(entry)}
              className={
                "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                hAdrColor +
                " text-theme-900"
              }
            >
              {entry}
            </span>
          ))}
      </td>
      <td>
        {rev.level.length > 0 &&
          rev.level.map((entry) => (
            <span
              key={"rev".concat(entry)}
              className={
                "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                hAdrColor +
                " text-theme-900"
              }
            >
              {entry}
            </span>
          ))}
      </td>
    </tr>
  );
}

export default connect((state, ownProps) => ({
  currency: state.currencies[ownProps.name],
}))(CurrencyRow);
