import { Form, Formik } from "formik";
import { ForgotPasswordSchema } from "../../helpers/validation/schemas";
import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import CustomField from "../../components/form/CustomField";
import { forgotPassword } from "../../api/user";
import { showNotification } from "../../store/actions/notification";
import { connect } from "react-redux";
import { isString } from "lodash";

const ForgotPasswordForm = (props) => {
  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, callbacks) => {
    try {
      await forgotPassword(values);
      props.showNotification({
        title: props.intl.formatMessage({ id: "password.forgot" }),
        message: props.intl.formatMessage({ id: "password.forgot.success" }),
      });
      callbacks.resetForm();
    } catch (e) {
      const message = isString(e.response.data.message)
        ? e.response.data.message
        : Object.values(e.response.data.message).join("<br/>");
      props.showNotification({
        type: "error",
        title: props.intl.formatMessage({ id: "password.forgot" }),
        message,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.email"
            name="email"
            type="email"
            required={true}
          />
          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
          >
            <FormattedMessage id="forms.buttons.reset-password" />
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default connect(null, (dispatch) => ({
  showNotification: ({ type, title, message }) =>
    dispatch(
      showNotification({
        type: type || "info",
        title,
        message,
      })
    ),
}))(injectIntl(ForgotPasswordForm));
