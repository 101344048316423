import moment from "moment";
import qs from "qs";
import { getAxios } from "./axios";
import { store } from "../store/store";

/**
 * Check if the current user is authenticated or not
 * @returns {boolean|*}
 */
export function isAuthenticated() {
  const state = store.getState();
  if (!state.user) {
    return false;
  }

  const token = state.user.token;

  if (!token || typeof token.access_token === "undefined") {
    return false;
  }

  return moment(token.expires_at).isAfter(moment());
}

export function extractRefreshToken() {
  return store.getState()?.user?.token?.refresh_token;
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

/**
 * Get access token header and pagination query string for requests
 * @param {object} [urlParams]
 * @param {number} [urlParams.page]
 * @param {number} [urlParams.per_page]
 * @param {string} [urlParams.sort_by]
 * @param {string} [urlParams.sort]
 * @param {string} [urlParams.q] Search
 * @param {string|number} [urlParams.custom]
 * @returns {{}}
 */
export function getTokenHeaders(urlParams) {
  const state = store.getState();
  const authToken = state.user.token.access_token;
  let params = {};

  if (authToken) {
    params.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    };

    if (urlParams) {
      params.params = buildQueryString(urlParams, "object");
    }

    return params;
  }

  return {
    headers: { "Content-Type": "application/json" },
  };
}

/**
 * Get query string from an URL
 * @param {object} location
 * @param {object} location.search
 * @returns {any}
 */
export function getQueryString(location) {
  return qs.parse(location.search, { ignoreQueryPrefix: true });
}

/**
 * Get default header object for requests with no authentication
 * @returns {{headers: {"Access-Control-Allow-Origin": string, "Access-Control-Allow-Headers": string, "Content-Type": string}}}
 */
export function getDefaultHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
  };
}

/**
 * Axios call for download files
 * @param {string} uri
 * @param {object} headers
 * @returns {Promise<T>}
 */
export const download = (uri, headers, name = "sample.pdf") => {
  headers.responseType = "blob";
  return getAxios()
    .get(uri, headers)
    .then((data) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;

      if (
        data &&
        data.headers["x-filename-download"] &&
        data.headers["x-filename-download"].length
      ) {
        name = data.headers["x-filename-download"];
      }

      link.setAttribute("download", name); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();

      return data.data;
    });
};

/**
 * Replace parameters from endpoint url
 * @param {string} url
 * @param {string|object} paramName
 * @param {string|null} [value]
 * @param {object} [filters]
 * @param {number} [filters.page]
 * @param {number} [filters.per_page]
 * @param {string} [filters.sort_by]
 * @param {string} [filters.sort]
 * @param {string} [filters.q]
 * @param {string|number} [filters.custom]
 * @returns {string}
 */
export function addParam(url, paramName, value, filters) {
  const acceptedValuesTypes = ["string", "number"];

  if (typeof url === "string" && url.length > 0) {
    if (
      typeof paramName === "string" &&
      paramName.length > 1 &&
      acceptedValuesTypes.indexOf(typeof value) !== -1
    ) {
      return url
        .replace(paramName, value.toString())
        .replace(/\/$/g, "")
        .concat(buildQueryString(filters, "string"));
    } else if (typeof paramName === "object") {
      const properties = Object.getOwnPropertyNames(paramName);
      properties.forEach((element) => {
        if (
          typeof element === "string" &&
          element.length > 1 &&
          acceptedValuesTypes.indexOf(typeof paramName[element]) !== -1
        ) {
          url = url.replace(element, paramName[element].toString());
        }
      });

      return url
        .replace(/\/$/g, "")
        .concat(buildQueryString(filters, "string"));
    }
  }

  return url;
}

/**
 * Build query string filters for a listing endpoint
 * @param {object} filters
 * @param {number} [filters.page]
 * @param {number} [filters.per_page]
 * @param {string} [filters.sort_by]
 * @param {string} [filters.sort]
 * @param {string} [filters.q] Search
 * @param {string} type
 * @param {string|number} [filters.custom]
 *
 * @returns {string|array}
 */
export function buildQueryString(filters, type = "string") {
  let queryString = "",
    params = {},
    key = 0;

  if (filters && typeof filters === "object") {
    for (key in filters) {
      if (filters[key] || filters[key] === 0) {
        params[key] = filters[key];

        if (queryString.length === 0) {
          queryString = `?${key}=${filters[key]}`;
        } else {
          queryString += `&${key}=${filters[key]}`;
        }
      }
    }
  }

  return type === "string" ? queryString : params;
}

export function timeFormat(time) {
  return moment.utc(time).local().format("MMM D HH:mm");
}

export function trimUSD(value) {
  return value?.toUpperCase()?.replace("USD", "");
}
