import React from "react";
import { Card, CardBody } from "../../card/card";
import { getHighlightColor } from "../../../helpers/currency";

function RSI({ data }) {
  const highlightColor = getHighlightColor(data);

  const rsiData = [
    {
      title: "1 min",
      level: data.rsi_1min,
      highlight: data.rsi_1min_highlight,
    },
    {
      title: "5 min",
      level: data.rsi_5min,
      highlight: data.rsi_5min_highlight,
    },
    {
      title: "15 min",
      level: data.rsi_15min,
      highlight: data.rsi_15min_highlight,
    },
    {
      title: "30 min",
      level: data.rsi_30min,
      highlight: data.rsi_30min_highlight,
    },
    {
      title: "1H",
      level: data.rsi_1h,
      highlight: data.rsi_1h_highlight,
    },
    {
      title: "4H",
      level: data.rsi_4h,
      highlight: data.rsi_4h_highlight,
    },
  ];

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex fw-bold small mb-3">
          <span className="flex-grow-1">RSI</span>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <thead>
              <tr>
                <th></th>
                <th>
                  <small>LEVEL</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {rsiData && rsiData.length > 0 ? (
                rsiData.map((log, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2 " +
                            (log.highlight
                              ? "text-theme"
                              : "text-white text-opacity-30")
                          }
                        ></i>
                        {log.title}
                      </span>
                    </th>
                    <td>
                      <span
                        key={"rsi".concat(log.level)}
                        className={
                          "animate-on-update badge d-block rounded-0 pt-5px w-70px " +
                          (log.highlight
                            ? highlightColor + " text-theme-900"
                            : "bg-white bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {log.level}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default RSI;
