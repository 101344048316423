import React, { useState } from "react";
import { Card, CardBody } from "../../components/card/card";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import CustomField from "../../components/form/CustomField";
import { connect } from "react-redux";
import { updateProfileDetails, updateProfileSecurity } from "../../api/user";
import {
  ProfileDetailsSchema,
  ProfileSecuritySchema,
} from "../../helpers/validation/schemas";
import { showNotification } from "../../store/actions/notification";
import { userInfo } from "../../store/actions/user";

function Profile(props) {
  const [detailsError, setDetailsError] = useState(null);
  const [securityError, setSecurityError] = useState(null);
  const { profile } = props;

  const initialDetailsValues = {
    email: profile.email,
    first_name: profile.first_name,
    last_name: profile.last_name,
  };

  const initialSecurityValues = {
    current_password: "",
    password: "",
    password_confirmation: "",
  };

  const onDetailsSubmit = (values, { setSubmitting, setFieldError }) => {
    if (securityError) {
      setSecurityError(null);
    }

    updateProfileDetails(values)
      .then((response) => {
        props.showNotification({
          title: props.intl.formatMessage({ id: "pages.profile.details" }),
          message: props.intl.formatMessage({
            id: "pages.profile.details.success",
          }),
        });
        const { id, first_name, last_name, email, currencySettings } =
          response.data.data;
        props.dispatch(
          userInfo({ id, first_name, last_name, email, currencySettings })
        );
      })
      .catch((reason) =>
        handleError(
          reason,
          initialDetailsValues,
          setDetailsError,
          setFieldError
        )
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSecuritySubmit = (values, { setSubmitting, setFieldError }) => {
    if (detailsError) {
      setDetailsError(null);
    }

    updateProfileSecurity(values)
      .then((response) => {
        props.showNotification({
          title: props.intl.formatMessage({ id: "pages.profile.security" }),
          message: props.intl.formatMessage({
            id: "pages.profile.security.success",
          }),
        });
        const { id, first_name, last_name, email, currencySettings } =
          response.data.data;
        props.dispatch(
          userInfo({ id, first_name, last_name, email, currencySettings })
        );
      })
      .catch((reason) =>
        handleError(
          reason,
          initialSecurityValues,
          setSecurityError,
          setFieldError
        )
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleError = (
    reason,
    initialValuesMap,
    stateToUpdate,
    setFieldError
  ) => {
    if (typeof reason.response.data.message === "string") {
      stateToUpdate(reason.response.data.message);
      return;
    }

    for (let error in reason.response.data.message) {
      if (initialValuesMap[error] !== undefined) {
        setFieldError(
          error,
          reason.response.data.message[error].join("<br />")
        );
      }
    }
  };

  return (
    <div className="row">
      <div className="col-xl-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small mb-3">
              <span className="flex-grow-1">
                <FormattedMessage id="pages.profile.details" />
              </span>
            </div>
            <Formik
              initialValues={initialDetailsValues}
              validationSchema={ProfileDetailsSchema}
              onSubmit={onDetailsSubmit}
            >
              {(formikProps) => (
                <Form>
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.email"
                    name="email"
                    type="email"
                    disabled={true}
                    required={false}
                  />
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.first_name"
                    name="first_name"
                    type="text"
                    required={true}
                  />
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.last_name"
                    name="last_name"
                    type="text"
                    required={true}
                  />
                  {detailsError !== null && (
                    <p className="invalid-feedback d-block">{detailsError}</p>
                  )}
                  <button
                    type="submit"
                    className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
                  >
                    <FormattedMessage id="forms.buttons.update_profile_details" />
                  </button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
      <div className="col-xl-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small mb-3">
              <span className="flex-grow-1">
                <FormattedMessage id="pages.profile.security" />
              </span>
            </div>
            <Formik
              initialValues={initialSecurityValues}
              validationSchema={ProfileSecuritySchema}
              onSubmit={onSecuritySubmit}
            >
              {(formikProps) => (
                <Form>
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.current_password"
                    name="current_password"
                    type="password"
                    required={false}
                  />
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.password"
                    name="password"
                    type="password"
                    required={false}
                  />
                  <CustomField
                    formikProps={formikProps}
                    labelId="forms.labels.password_confirmation"
                    name="password_confirmation"
                    type="password"
                    required={false}
                  />
                  {securityError !== null && (
                    <p className="invalid-feedback d-block">{securityError}</p>
                  )}
                  <button
                    type="submit"
                    className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
                  >
                    <FormattedMessage id="forms.buttons.update_profile_password" />
                  </button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ profile: state.user.profile }),
  (dispatch) => ({
    dispatch,
    showNotification: ({ type, title, message }) =>
      dispatch(
        showNotification({
          type: type || "info",
          title,
          message,
        })
      ),
  })
)(injectIntl(Profile));
