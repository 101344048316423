import React from "react";
import { initiateLogOutAction } from "../../store/actions/user";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../../config/routes";

function Logout(props) {
  const navigation = useNavigate();
  setTimeout(() => {
    props.logout();
    navigation(ROUTE_HOME);
  });
  return <></>;
}

export default connect(null, (dispatch) => ({
  logout: () => dispatch(initiateLogOutAction()),
}))(Logout);
