import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

export const emailRules = Yup.string()
  .matches(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:_@"]+([\._][^<>()[\]\\.,;:_@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    {
      message: <FormattedMessage id="validation.email_valid" />,
    }
  )
  .email(<FormattedMessage id="validation.email_valid" />)
  .required(<FormattedMessage id="validation.email_required" />);

export const passwordRules = Yup.string()
  .required(<FormattedMessage id="validation.password_required" />)
  .matches(/^.{8,}$/, {
    message: <FormattedMessage id="validation.password_characters" />,
    excludeEmptyString: true,
  })
  .matches(/^(?=.*[A-Z]).*$/, {
    message: <FormattedMessage id="validation.password_uppercase" />,
    excludeEmptyString: true,
  })
  .matches(/^(?=.*[a-z]).*$/, {
    message: <FormattedMessage id="validation.password_lowercase" />,
    excludeEmptyString: true,
  })
  .matches(/^(?=.*\d).*$/, {
    message: <FormattedMessage id="validation.password_number" />,
    excludeEmptyString: true,
  })
  .matches(/^(?=.*[@$!%*#?&()[\]{}_\-+=."';:<>]).*$/, {
    message: <FormattedMessage id="validation.password_special_char" />,
    excludeEmptyString: true,
  });
export const firstNameRules = Yup.string()
  .trim()
  .min(3, <FormattedMessage id="validation.first_name_min_length" />)
  .max(42, <FormattedMessage id="validation.first_name_length" />)
  .matches(
    /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
    {
      message: (
        <FormattedMessage id="validation.first_name.special_characters" />
      ),
      excludeEmptyString: true,
    }
  )
  .matches(
    /^(?=.*[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])/u,
    {
      message: (
        <FormattedMessage id="validation.first_name.at_least_one_alpha_char" />
      ),
      excludeEmptyString: true,
    }
  )
  .required(<FormattedMessage id="validation.first_name" />);

export const lastNameRules = Yup.string()
  .trim()
  .min(3, <FormattedMessage id="validation.last_name_min_length" />)
  .max(42, <FormattedMessage id="validation.last_name_length" />)
  .matches(
    /^[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/u,
    {
      message: (
        <FormattedMessage id="validation.last_name.special_characters" />
      ),
      excludeEmptyString: true,
    }
  )
  .matches(
    /^(?=.*[a-zA-ZăĂțȚșȘàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])/u,
    {
      message: (
        <FormattedMessage id="validation.last_name.at_least_one_alpha_char" />
      ),
      excludeEmptyString: true,
    }
  )
  .required(<FormattedMessage id="validation.last_name" />);

export const passwordConfirmationRules = Yup.string()
  .oneOf(
    [Yup.ref("password"), null],
    <FormattedMessage id="validation.password_mismatch" />
  )
  .required(
    <FormattedMessage id="validation.confirmation_password_required" />
  );

export const termsAndConditionsRules = Yup.boolean().test(
  "terms_and_conditions",
  <FormattedMessage id="validation.terms_and_conditions" />,
  (value) => {
    return !!(typeof value !== "undefined" && value);
  }
);
