import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import SinglePageForm, {
  ALTERNATIVE_LINK_LOGIN,
} from "../single-page-form/SinglePageForm";

const ResetPassword = () => {
  return (
    <SinglePageForm
      titleId="pages.reset-password.title"
      subtitleId="pages.reset-password.subtitle"
      formComponent={<ResetPasswordForm />}
      alternativeLink={ALTERNATIVE_LINK_LOGIN}
    />
  );
};

export default ResetPassword;
