import { debounce, put, takeEvery } from "redux-saga/effects";
import { loadProfile, logout, updateCurrencySettings } from "../../../api/user";
import {
  CURRENCY_SETTINGS_UPDATE,
  USER_LOAD_PROFILE,
  USER_LOGOUT_INITIATED,
} from "../../actions/types";
import {
  logOutAction,
  userInfo,
  userInfoClear,
  userInfoLoading,
} from "../../actions/user";

export default function* userSaga() {
  yield takeEvery(USER_LOGOUT_INITIATED, function* () {
    logout().catch(() => {
      //noop
    });
    yield put(logOutAction());
    yield put(userInfoClear()); // TODO maybe combine actions
  });

  yield takeEvery(USER_LOAD_PROFILE, function* () {
    yield put(userInfoLoading());
    const response = yield loadProfile(); // TODO handle on error
    const { id, first_name, last_name, email, currencySettings } =
      response.data.data;
    yield put(userInfo({ id, first_name, last_name, email, currencySettings }));
  });

  yield debounce(1000, CURRENCY_SETTINGS_UPDATE, function* ({ payload }) {
    yield updateCurrencySettings(payload);
  });
}
