import React, { useEffect } from "react";
import { connect } from "react-redux";
import { socketConnect, socketLogout } from "../store/actions/socket";

const SocketObserver = ({ token, id, socketLogout, socketConnect }) => {
  useEffect(() => {
    return () => {
      socketLogout();
    };
  });

  useEffect(() => {
    if (!token) {
      socketLogout();
    } else {
      socketConnect(id || 1);
    }

    // eslint-disable-next-line
  }, [token]);

  return <></>;
};

export default connect(
  (state) => ({
    token: state.user.token.access_token,
    id: state.user.profile.id,
  }),
  (dispatch) => ({
    socketLogout: () => dispatch(socketLogout()),
    socketConnect: ({ id }) => dispatch(socketConnect(id)),
  })
)(SocketObserver);
