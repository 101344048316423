import React from "react";
import Notification from "./Notification";
import { connect } from "react-redux";

function NotificationContainer(props) {
  return (
    props.notifications.length > 0 && (
      <div className="toasts-container">
        {props.notifications.map((notification) => (
          <Notification
            key={notification.notificationId}
            notificationId={notification.notificationId}
            title={notification.title}
            message={notification.message}
          />
        ))}
      </div>
    )
  );
}

export default connect((state) => ({
  notifications: state.notifications,
}))(NotificationContainer);
