import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { RegisterSchema } from "../../helpers/validation/schemas";
import CustomField from "../../components/form/CustomField";
import { FormattedMessage, injectIntl } from "react-intl";
import { register as apiCall } from "../../api/user";
import { connect } from "react-redux";
import { showInfoNotification } from "../../store/actions/notification";
import { ROUTE_LOGIN } from "../../config/routes";
import { useNavigate } from "react-router-dom";

const RegisterForm = (props) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    terms_and_conditions: false,
  };

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    if (error) {
      setError(null);
    }

    apiCall(values)
      .then(() => {
        props.showInfoNotification(
          props.intl.formatMessage({ id: "register.account" }),
          props.intl.formatMessage({ id: "register.account.success" })
        );
        navigate(ROUTE_LOGIN);
      })
      .catch((reason) => {
        if (typeof reason.response.data.message === "string") {
          setError(reason.response.data.message);
          return;
        }

        for (let error in reason.response.data.message) {
          if (initialValues[error] !== undefined) {
            setFieldError(
              error,
              reason.response.data.message[error].join("<br />")
            );
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form>
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.first_name"
            name="first_name"
            type="text"
            required={true}
          />
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.last_name"
            name="last_name"
            type="text"
            required={true}
          />
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.email"
            name="email"
            type="email"
            required={true}
          />
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.password"
            name="password"
            type="password"
            required={true}
          />
          <CustomField
            formikProps={formikProps}
            labelId="forms.labels.password_confirmation"
            name="password_confirmation"
            type="password"
            required={true}
          />

          <div className="mb-3">
            <div className="form-check">
              <label
                className="form-check-label"
                htmlFor="terms_and_conditions_checkbox"
              >
                <FormattedMessage
                  id="pages.register.agree_terms"
                  values={{
                    terms: (
                      <a href={"/terms"}>
                        <FormattedMessage id="links.terms" />
                      </a>
                    ),
                    privacy: (
                      <a href={"/privacy"}>
                        <FormattedMessage id="links.privacy" />
                      </a>
                    ),
                  }}
                />
              </label>
              <Field
                type="checkbox"
                name="terms_and_conditions"
                id="terms_and_conditions_checkbox"
                className={`form-check-input ${
                  formikProps.touched.terms_and_conditions &&
                  formikProps.errors.terms_and_conditions
                    ? "is-invalid"
                    : ""
                }`}
              />
              <ErrorMessage
                component="div"
                name="terms_and_conditions"
                className="invalid-feedback"
              />
            </div>
          </div>
          {error !== null && (
            <p className="invalid-feedback d-block">{error}</p>
          )}
          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
          >
            <FormattedMessage id="forms.buttons.sign_up" />
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default connect(null, (dispatch) => ({
  showInfoNotification: (title, message) =>
    dispatch(
      showInfoNotification({
        title,
        message,
      })
    ),
}))(injectIntl(RegisterForm));
