import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/types";

const initialState = [];

function addNotification(state, action) {
  return [
    ...state,
    {
      message: action.payload.message || null,
      title: action.payload.title || null,
      type: action.payload.type || null,
      notificationId: action.payload.notificationId || null,
    },
  ];
}

function removeNotification(state, action) {
  return [
    ...state.filter(
      (notification) =>
        notification.notificationId !== action.payload.notificationId
    ),
  ];
}

const notification = function (state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return addNotification(state, action);
    case HIDE_NOTIFICATION:
      return removeNotification(state, action);
    default:
      return state;
  }
};

export default notification;
