import * as types from "../actions/types";

const initialState = {
  appShowSidebar: false,
  appShowContent: false,
  appShowHeader: false,
};

export const appState = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_SHOW_SIDEBAR:
      return { ...state, appShowSidebar: true };
    case types.APP_HIDE_SIDEBAR:
      return { ...state, appShowSidebar: false };
    case types.APP_SHOW_CONTENT:
      return { ...state, appShowContent: true };
    case types.APP_HIDE_CONTENT:
      return { ...state, appShowContent: false };
    case types.APP_SHOW_HEADER:
      return { ...state, appShowHeader: true };
    case types.APP_HIDE_HEADER:
      return { ...state, appShowHeader: false };
    default:
      return state;
  }
};
