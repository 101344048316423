import React from "react";

export function CurrencySettingsEntry(props) {
  const { order, alert, currency, enabled } = props.currencySettings;
  const { name } = currency;
  const { canMoveUp, canModeDown } = props;

  return (
    <div className="col-md-12 mb-4">
      <div
        className="d-flex justify-content-between"
        style={{ maxWidth: "10rem" }}
      >
        <div className="text-white text-opacity-50 small mb-2">
          <b>
            {name} ({order + 1})
          </b>
        </div>
        <div>
          <i
            className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-up"
            style={canMoveUp ? {} : { color: "#575e68" }}
            onClick={props.onMoveUp}
          ></i>
          <i
            className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-down"
            style={canModeDown ? {} : { color: "#575e68" }}
            onClick={props.onMoveDown}
          ></i>
        </div>
      </div>
      <div className="list-group">
        <div className="list-group-item d-flex align-items-center">
          <div className="flex-fill">
            <div className="fw-bold">Show in menu</div>
          </div>
          <div className="form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              checked={enabled}
              onChange={props.onEnabledToggle}
            />
          </div>
        </div>
        <div className="list-group-item d-flex align-items-center">
          <div className="flex-fill">
            <div className="fw-bold">Receive alerts</div>
          </div>
          <div className="form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              checked={alert}
              onChange={props.onAlertToggle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
