import React from "react";
import { connect } from "react-redux";
import Pace from "./components/common/Pace";
import NotificationContainer from "./components/notification/NotificationContainer";

const AppContainer = ({ loading, children }) => {
  return (
    <>
      {loading.pendingRequests > 0 && <Pace />}
      {children}
      <NotificationContainer />
    </>
  );
};

export default connect((state) => ({ ...state.app, loading: state.loading }))(
  AppContainer
);
