import {
  SHOW_GLOBAL_NOTIFICATION,
  HIDE_GLOBAL_NOTIFICATION,
} from "../actions/types";

const initialState = {
  message: null,
  type: null,
  showGlobalNotification: null,
};

const globalNotification = function (state = initialState, action) {
  switch (action.type) {
    case SHOW_GLOBAL_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message || null,
        type: action.payload.type || null,
        showGlobalNotification: true,
      };
    case HIDE_GLOBAL_NOTIFICATION:
      return {
        ...state,
        type: null,
        message: null,
        showGlobalNotification: false,
      };
    default:
      return state;
  }
};

export default globalNotification;
