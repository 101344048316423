import * as types from "../actions/types";

const initialState = {
  EURUSD: {},
  GBPUSD: {},
  AUDUSD: {},
  NZDUSD: {},
  DXY: {},
  USDCAD: {},
  USDCHF: {},
  USDJPY: {},
};

const currency = function (state = initialState, action) {
  switch (action.type) {
    case types.CURRENCY_UPDATE:
      const { name, data } = action.payload;
      Object.keys(data).forEach((key) => {
        state[name][key] = data[key];
      });

      return {
        ...state,
        [name]: {
          ...state[name],
        },
      };
    default:
      return state;
  }
};

export default currency;
