import {
  ROUTE_CURRENCY,
  ROUTE_DASHBOARD,
  ROUTE_ENTRY_REQUIREMENTS,
} from "./routes";

const Menu = [
  { is_header: true, title: "Navigation" },
  { path: ROUTE_DASHBOARD, icon: "bi bi-cpu", title: "Dashboard" },
  {
    path: ROUTE_ENTRY_REQUIREMENTS,
    icon: "bi bi-broadcast",
    title: "Entry requirements",
  },
  {
    path: ROUTE_CURRENCY,
    icon: "bi bi-envelope",
    title: "Currency",
    children: [],
  },
];

export default Menu;
