import {
  NEWS,
  NEWS_BY_CURRENCY,
  NEWS_UPDATE_TIME_LEFT,
  NEWS_UPDATE_TIME_LEFT_ALL,
} from "../actions/types";
import moment from "moment";

const initialState = {
  news: [],
  newsByCurrency: {},
};

const news = function (state = initialState, action) {
  switch (action.type) {
    case NEWS:
      return {
        ...state,
        news: updateNewsTimeLeft(action.payload.news),
      };
    case NEWS_BY_CURRENCY:
      return {
        ...state,
        newsByCurrency: {
          ...state.newsByCurrency,
          [action.payload.currencyCode]: updateNewsTimeLeft(
            action.payload.news
          ),
        },
      };
    case NEWS_UPDATE_TIME_LEFT_ALL:
      return {
        ...state,
        news: updateNewsTimeLeft(state.news),
      };
    case NEWS_UPDATE_TIME_LEFT:
      return {
        ...state,
        newsByCurrency: {
          ...state.newsByCurrency,
          [action.payload.currencyCode]: updateNewsTimeLeft(
            state.newsByCurrency[action.payload.currencyCode]
          ),
        },
      };
    default:
      return state;
  }
};

function updateNewsTimeLeft(news) {
  return news.map((newsItem) => {
    return {
      ...newsItem,
      time_left: moment.utc(newsItem.time).local().fromNow(),
    };
  });
}

export default news;
