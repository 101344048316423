import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import SinglePageForm, {
  ALTERNATIVE_LINK_LOGIN,
} from "../single-page-form/SinglePageForm";

const ForgotPassword = () => {
  return (
    <SinglePageForm
      titleId="pages.forgot-password.title"
      subtitleId="pages.forgot-password.subtitle"
      formComponent={<ForgotPasswordForm />}
      alternativeLink={ALTERNATIVE_LINK_LOGIN}
    />
  );
};

export default ForgotPassword;
