import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/store";
import ConnectedIntlProvider from "./ConnectedIntlProvider";
import "./prototypes";

import AppRoute from "./config/app-route.js";

// bootstrap
import "bootstrap";

// css
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/styles.scss";
import AppContainer from "./AppContainer";

const root = ReactDOM.createRoot(document.getElementById("root"));

function removeSidebarOnMobileOnRouteChange() {
  const appElement = document.querySelector(".app");
  if (appElement) {
    appElement.classList.remove("app-sidebar-mobile-toggled");
  }
}

function AppEntrypoint() {
  let element = useRoutes(AppRoute);
  let location = useLocation();

  React.useEffect(() => {
    removeSidebarOnMobileOnRouteChange();
  }, [location]);

  return element;
}

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedIntlProvider>
          <AppContainer>
            <AppEntrypoint />
          </AppContainer>
        </ConnectedIntlProvider>
      </PersistGate>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
