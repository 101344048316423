import { FormattedMessage } from "react-intl";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_LOGIN, ROUTE_REGISTER } from "../../config/routes";

export const ALTERNATIVE_LINK_REGISTER = {
  titleId: "pages.login.dont_have_account",
  route: ROUTE_REGISTER,
  messageId: "links.sign_up",
};

export const ALTERNATIVE_LINK_LOGIN = {
  titleId: "pages.register.have_account",
  route: ROUTE_LOGIN,
  messageId: "links.sign_in",
};

const SinglePageForm = ({
  titleId,
  subtitleId,
  formComponent,
  alternativeLink,
}) => {
  return (
    <div className="single-page">
      <div className="single-page-content">
        <h1 className="text-center">
          <FormattedMessage id={titleId} />
        </h1>
        <div className="text-white text-opacity-50 text-center mb-4">
          <FormattedMessage id={subtitleId} />
        </div>
        {formComponent}
        <div className="text-center text-white text-opacity-50">
          <FormattedMessage id={alternativeLink.titleId} />{" "}
          <Link to={alternativeLink.route}>
            <FormattedMessage id={alternativeLink.messageId} />
          </Link>
          .
        </div>
      </div>
    </div>
  );
};

export default SinglePageForm;
