import {
  INCREMENT_PENDING_REQUESTS,
  DECREMENT_PENDING_REQUESTS,
} from "./types";

export const showSpinner = () => ({
  type: INCREMENT_PENDING_REQUESTS,
});

export const hideSpinner = () => ({
  type: DECREMENT_PENDING_REQUESTS,
});
