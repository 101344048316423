import { getAxios, getAxiosWithoutLoading } from "./axios";
import {
  ENDPOINT_FORGOT,
  ENDPOINT_LOGIN,
  ENDPOINT_LOGOUT,
  ENDPOINT_REFRESH,
  ENDPOINT_USER,
  ENDPOINT_USER_CURRENCY_SETTINGS,
  ENDPOINT_USER_PROFILE,
  ENDPOINT_USER_PROFILE_SECURITY,
  ENDPOINT_VERIFY,
} from "../config/endpoints";
import { getDefaultHeaders, getTokenHeaders } from "./helper";

export function login(payload) {
  return getAxios().post(ENDPOINT_LOGIN, payload, getDefaultHeaders());
}

export function forgotPassword(payload) {
  return getAxios().post(ENDPOINT_FORGOT, payload, getDefaultHeaders());
}

export function refreshAccessToken(refreshToken) {
  return getAxiosWithoutLoading().post(
    ENDPOINT_REFRESH,
    { refresh_token: refreshToken },
    getDefaultHeaders()
  );
}

export function resetPassword(token, payload) {
  return getAxios().put(
    `${ENDPOINT_FORGOT}/${token}`,
    payload,
    getDefaultHeaders()
  );
}

export function logout(payload) {
  return getAxiosWithoutLoading().post(
    ENDPOINT_LOGOUT,
    payload,
    getTokenHeaders()
  );
}

export function register(payload) {
  return getAxios().post(ENDPOINT_USER, payload, getDefaultHeaders());
}

export function loadProfile() {
  return getAxiosWithoutLoading().get(ENDPOINT_USER_PROFILE, getTokenHeaders());
}

export function updateProfileDetails(payload) {
  return getAxios().put(ENDPOINT_USER_PROFILE, payload, getTokenHeaders());
}
export function updateProfileSecurity(payload) {
  return getAxios().put(
    ENDPOINT_USER_PROFILE_SECURITY,
    payload,
    getTokenHeaders()
  );
}

export function updateCurrencySettings(payload) {
  return getAxiosWithoutLoading().put(
    ENDPOINT_USER_CURRENCY_SETTINGS,
    payload,
    getTokenHeaders()
  );
}

export function verifyAccount({
  accountId,
  verificationToken,
  expires,
  signature,
}) {
  return getAxios().put(
    `${ENDPOINT_VERIFY}/${accountId}/${verificationToken}`,
    null,
    {
      ...getDefaultHeaders(),
      params: { expires, signature },
    }
  );
}
