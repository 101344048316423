import * as types from "../actions/types";

const initialState = {
  cookieSetTime: null,
};

const cookie = function (state = initialState, action) {
  switch (action.type) {
    case types.STORE_COOKIE_DATE:
      return {
        ...state,
        cookieSetTime: action.payload || null,
      };

    default:
      return state;
  }
};

export default cookie;
