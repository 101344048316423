import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { verifyAccount } from "../../api/user";
import { connect } from "react-redux";
import { showNotification } from "../../store/actions/notification";
import { ROUTE_LOGIN } from "../../config/routes";

const AccountVerification = (props) => {
  const [searchParams] = useSearchParams();
  const { accountId, verificationToken } = useParams();
  let navigate = useNavigate();
  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");
  useEffect(() => {
    (async () => {
      try {
        await verifyAccount({
          accountId,
          verificationToken,
          expires,
          signature,
        });
        props.showNotification({
          type: "info",
          title: props.intl.formatMessage({ id: "account-verification" }),
          message: props.intl.formatMessage({
            id: "account-verification.success",
          }),
        });
      } catch (e) {
        props.showNotification({
          type: "error",
          title: props.intl.formatMessage({ id: "account-verification" }),
          message: props.intl.formatMessage({
            id: "account-verification.error",
          }),
        });
      }
      navigate(ROUTE_LOGIN);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="single-page">
      <div className="single-page-content">
        <h1 className="text-center">
          <FormattedMessage id="pages.account-verification.title" />
        </h1>
        <div className="text-white text-opacity-50 text-center mb-4">
          <FormattedMessage id="pages.account-verification.subtitle" />
        </div>
      </div>
    </div>
  );
};
export default connect(null, (dispatch) => ({
  showNotification: ({ type, title, message }) =>
    dispatch(
      showNotification({
        type,
        message,
        title,
      })
    ),
}))(injectIntl(AccountVerification));
