import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../api/helper";
import { ROUTE_LOGIN } from "../config/routes";

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to={ROUTE_LOGIN} replace></Navigate>;
  }
  return children;
};

export default ProtectedRoute;
