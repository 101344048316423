import React from "react";
import RegisterForm from "./RegisterForm";
import SinglePageForm, {
  ALTERNATIVE_LINK_LOGIN,
} from "../single-page-form/SinglePageForm";

const Register = () => {
  return (
    <SinglePageForm
      titleId="pages.register.title"
      subtitleId="pages.register.subtitle"
      formComponent={<RegisterForm />}
      alternativeLink={ALTERNATIVE_LINK_LOGIN}
    />
  );
};

export default Register;
