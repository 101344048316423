import { USER_LOCALE } from "../actions/types";

const initialState = {
  lang: "en",
};

const locale = function (state = initialState, action) {
  switch (action.type) {
    case USER_LOCALE:
      return {
        ...state,
        lang: action.payload.lang,
      };
    default:
      return state;
  }
};

export default locale;
