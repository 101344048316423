import * as types from "./types";
import { v4 as uuidv4 } from "uuid";
import { store } from "../store";

const NOTIFICATION_DISPLAY_TIME =
  process.env.REACT_APP_NOTIFICATION_DISPLAY_TIME ?? 5000;

export const showNotification = ({
  type,
  message,
  title,
  transient = true,
  displayTime = NOTIFICATION_DISPLAY_TIME,
  notificationId = uuidv4(),
}) => {
  if (transient) {
    setTimeout(
      () => store.dispatch(hideNotification(notificationId)),
      displayTime
    );
  }

  return {
    type: types.SHOW_NOTIFICATION,
    payload: {
      type,
      title,
      message,
      notificationId,
    },
  };
};

export const showErrorNotification = ({
  title,
  message,
  transient,
  displayTime,
}) => {
  return showNotification({
    type: "error",
    title,
    message,
    transient,
    displayTime,
  });
};

export const showInfoNotification = ({
  title,
  message,
  transient,
  displayTime,
}) => {
  return showNotification({
    type: "info",
    title,
    message,
    transient,
    displayTime,
  });
};

export const hideNotification = (notificationId) => ({
  type: types.HIDE_NOTIFICATION,
  payload: {
    notificationId,
  },
});
