import { FormattedMessage } from "react-intl";
import { ErrorMessage, Field } from "formik";
import React from "react";

const CustomField = ({
  formikProps,
  labelId,
  name,
  type = "text",
  required = false,
  autoComplete = "off",
  disabled = false,
}) => {
  return (
    <div className="mb-3">
      <label className="form-label">
        <FormattedMessage id={labelId} />{" "}
        {required && <span className="text-danger">*</span>}
      </label>
      <Field
        type={type}
        name={name}
        disabled={disabled}
        autoComplete={autoComplete}
        className={`form-control form-control-lg bg-white bg-opacity-5 ${
          formikProps.touched[name] && formikProps.errors[name]
            ? "is-invalid"
            : ""
        }`}
      />
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export default CustomField;
