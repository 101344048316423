import * as types from "./types";

export const updateAllNews = (news) => ({
  type: types.NEWS,
  payload: {
    news,
  },
});

export const updateNewsByCurrency = (currencyCode, news) => ({
  type: types.NEWS_BY_CURRENCY,
  payload: {
    currencyCode,
    news,
  },
});

export const updateAllNewsTimeLeft = () => ({
  type: types.NEWS_UPDATE_TIME_LEFT_ALL,
});

export const updateNewsTimeLeft = (currencyCode) => ({
  type: types.NEWS_UPDATE_TIME_LEFT,
  payload: { currencyCode },
});
