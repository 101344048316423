import React, { useEffect } from "react";
import { Card, CardBody } from "../../components/card/card";
import CurrencyRow from "./CurrencyRow";
import { connect } from "react-redux";
import News from "../../components/pages/currency/News";
import { store } from "../../store/store";
import { updateAllNews, updateAllNewsTimeLeft } from "../../store/actions/news";
import { UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS } from "../../constants";
import { getNews } from "../../api/news";

function Dashboard(props) {
  const currencies = props.currencySettings
    .filter((currencySettings) => currencySettings.enabled)
    .map((currencySettings) => currencySettings.currency.name);

  useEffect(() => {
    const interval = setInterval(() => {
      store.dispatch(updateAllNewsTimeLeft());
    }, UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS * 1000);

    getNews().then((response) => {
      store.dispatch(updateAllNews(response.data.data));
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="col-xl-12">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small mb-3">
              <span className="flex-grow-1">Overview</span>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-borderless mb-2px small text-nowrap">
                <thead>
                  <tr>
                    <th>
                      <small>SYMBOL</small>
                    </th>
                    <th>
                      <small>ADR</small>
                    </th>
                    <th>
                      <small>RSI</small>
                    </th>
                    <th>
                      <small>PIVOT</small>
                    </th>
                    <th>
                      <small>RESISTANCE</small>
                    </th>
                    <th>
                      <small>SUPPORT</small>
                    </th>
                    <th>
                      <small>REVERSAL</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currencies && currencies.length > 0 ? (
                    currencies.map((currency) => (
                      <CurrencyRow key={currency} name={currency} />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
      <News data={props.news} />
    </div>
  );
}

export default connect(
  (state) => ({
    currencySettings: state.user.profile.currencySettings,
    news: state.news.news,
  }),
  null
)(Dashboard);
