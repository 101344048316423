import React from "react";
import { Card, CardBody } from "../../components/card/card";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { CurrencySettingsEntry } from "./CurrencySettingsEntry";
import { currencySettingsUpdate } from "../../store/actions/user";

function Settings(props) {
  const currenciesSettings = props.currencySettings;

  const moveUp = (currencySettings) => {
    const currentIndex = findIndex(currencySettings);
    if (currentIndex === 0) {
      return;
    }
    const nextIndex = currentIndex - 1;
    swap(currentIndex, nextIndex);
  };

  const moveDown = (currencySettings) => {
    const currentIndex = findIndex(currencySettings);
    if (currentIndex === currenciesSettings.length - 1) {
      return;
    }
    const nextIndex = currentIndex + 1;
    swap(currentIndex, nextIndex);
  };

  const swap = (currentIndex, nextIndex) => {
    currenciesSettings.swapIndexes(currentIndex, nextIndex);
    updateCurrenciesSettingOrder(currentIndex, nextIndex);
    props.updateCurrencySettings(currenciesSettings);
  };

  const alertToggle = (currencySettings) => {
    currencySettings.alert = !currencySettings.alert;
    props.updateCurrencySettings(currenciesSettings);
  };

  const enableToggle = (currencySettings) => {
    currencySettings.enabled = !currencySettings.enabled;
    props.updateCurrencySettings(currenciesSettings);
  };

  const findIndex = (currencySettings) => {
    return currenciesSettings.findIndex((c) => c.id === currencySettings.id);
  };

  const updateCurrenciesSettingOrder = (currentIndex, nextIndex) => {
    currenciesSettings[currentIndex].order = currentIndex;
    currenciesSettings[nextIndex].order = nextIndex;
  };

  return (
    <div>
      <div className="col-xl-12">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small mb-3">
              <span className="flex-grow-1">
                <FormattedMessage id="pages.settings" />
              </span>
            </div>
            <div className="row">
              {currenciesSettings.map((currencySettings, index) => (
                <CurrencySettingsEntry
                  key={currencySettings.id}
                  currencySettings={currencySettings}
                  onMoveUp={() => moveUp(currencySettings)}
                  onMoveDown={() => moveDown(currencySettings)}
                  onAlertToggle={() => alertToggle(currencySettings)}
                  onEnabledToggle={() => enableToggle(currencySettings)}
                  canMoveUp={index !== 0}
                  canModeDown={index !== currenciesSettings.length - 1}
                />
              ))}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currencySettings: state.user.profile.currencySettings,
  }),
  (dispatch) => ({
    updateCurrencySettings: (currenciesSettings) =>
      dispatch(currencySettingsUpdate(currenciesSettings)),
  })
)(Settings);
