/**
 * Axios
 */
export const SUCCESS_AXIOS_CALL = "SUCCESS_AXIOS_CALL";
export const FAILD_AXIOS_CALL = "FAILD_AXIOS_CALL";
export const RESET_AXIOS_CALL = "RESET_AXIOS_CALL";

/**
 * Cookie Modal
 */
export const STORE_COOKIE_DATE = "Store_COOKIE_DATA";

/**
 * Global Notifications
 */
export const SHOW_GLOBAL_NOTIFICATION = "SHOW_GLOBAL_NOTIFICATION";
export const HIDE_GLOBAL_NOTIFICATION = "HIDE_GLOBAL_NOTIFICATION";

/**
 * Loading
 */
export const INCREMENT_PENDING_REQUESTS = "INCREMENT_PENDING_REQUESTS";
export const DECREMENT_PENDING_REQUESTS = "DECREMENT_PENDING_REQUESTS";

/**
 * Locales
 */
export const USER_LOCALE = "USER_LOCALE";

/**
 * Modal toggle
 */
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const RESET_MODAL = "RESET_MODAL";

/**
 * Notifications
 */
export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_WARNING = "NOTIFICATION_WARNING";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

/**
 * Socket
 */
export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_LOGOUT = "SOCKET_LOGOUT";

/**
 * User
 */
export const TOKEN_DETAILS = "TOKEN_DETAILS";
export const USER_INFO = "USER_INFO";
export const USER_INFO_LOADING = "USER_INFO_LOADING";
export const USER_LOAD_PROFILE = "USER_LOAD_PROFILE";
export const VERIFY_TOKEN_IS_VALID = "VERIFY_TOKEN_IS_VALID";
export const USER_LOGOUT_INITIATED = "USER_LOGOUT_INITIATED";
export const USER_LOGOUT = "USER_LOGOUT";

/**
 * Currency
 */
export const CURRENCY_RECEIVED = "CURRENCY_RECEIVED";
export const CURRENCY_UPDATE = "CURRENCY_UPDATE";

/**
 * App state
 */

export const APP_SHOW_SIDEBAR = "APP_SHOW_SIDEBAR";
export const APP_HIDE_SIDEBAR = "APP_HIDE_SIDEBAR";
export const APP_SHOW_CONTENT = "APP_SHOW_CONTENT";
export const APP_HIDE_CONTENT = "APP_HIDE_CONTENT";
export const APP_SHOW_HEADER = "APP_SHOW_HEADER";
export const APP_HIDE_HEADER = "APP_HIDE_HEADER";

/**
 * Currency settings
 */
export const CURRENCY_SETTINGS_UPDATE = "CURRENCY_SETTINGS_UPDATE";

/**
 * News
 */
export const NEWS = "NEWS";
export const NEWS_BY_CURRENCY = "NEWS_BY_CURRENCY";
export const NEWS_UPDATE_TIME_LEFT = "NEWS_UPDATE_TIME_LEFT";
export const NEWS_UPDATE_TIME_LEFT_ALL = "NEWS_UPDATE_TIME_LEFT_ALL";
