import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import localeReducer from "./locale";
import userReducer from "./user";
import notificationReducer from "./notification";
import loadingReducer from "./loading";
import axiosStatusNotificationReducer from "./axiosStatusNotification";
import modalToggleReducer from "./modalToggle";
import cookieReducer from "./cookie";
import currenciesReducer from "./currencies";
import globalNotificationReducer from "./globalNotification";
import { appState } from "./app";
import news from "./news";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications: notificationReducer,
    locale: localeReducer,
    user: userReducer,
    loading: loadingReducer,
    axiosStatusNotification: axiosStatusNotificationReducer,
    modalToggle: modalToggleReducer,
    cookie: cookieReducer,
    currencies: currenciesReducer,
    globalNotification: globalNotificationReducer,
    news: news,
    app: appState,
  });

export default reducers;
