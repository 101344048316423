import { TOGGLE_MODAL, RESET_MODAL } from "../actions/types";

const initialState = {
  showModal: false,
};

const toggle = function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };

    case RESET_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};

export default toggle;
