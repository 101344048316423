import React from "react";
import App from "./../App.js";
import { Navigate } from "react-router-dom";

import Dashboard from "./../pages/dashboard/Dashboard";
import Details from "../pages/currency/Details";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import ProtectedRoute from "../routes/ProtectedRoute";
import Logout from "../pages/logout/Logout";
import ForgotPassword from "../pages/forgot-password/ForgotPassword";
import ResetPassword from "../pages/reset-password/ResetPassword";
import {
  ROUTE_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_REGISTER,
  ROUTE_VERIFY_ACCOUNT,
} from "./routes";
import AccountVerification from "../pages/account-verification/AccountVerification";
import SocketObserver from "../components/SocketObserver";
import Profile from "../pages/profile/Profile";
import Settings from "../pages/settings/Settings";
import EntryRequirements from "../pages/entry-requirements/EntryRequirements";

const AppRoute = [
  {
    path: ROUTE_LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTE_REGISTER,
    element: <Register />,
  },
  {
    path: ROUTE_FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: `${ROUTE_FORGOT_PASSWORD}/:resetToken`,
    element: <ResetPassword />,
  },
  {
    path: `${ROUTE_VERIFY_ACCOUNT}/:accountId/:verificationToken`,
    element: <AccountVerification />,
  },
  {
    path: "*",
    element: (
      <ProtectedRoute>
        <App />
        <SocketObserver />
      </ProtectedRoute>
    ),
    children: [
      { path: "", element: <Navigate to={ROUTE_DASHBOARD} /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "entry-requirements", element: <EntryRequirements /> },
      { path: "profile", element: <Profile /> },
      { path: "settings", element: <Settings /> },
      {
        path: "currency/*",
        children: [
          { path: "eurusd", element: <Details currencyName={"EURUSD"} /> },
          { path: "gbpusd", element: <Details currencyName={"GBPUSD"} /> },
          { path: "audusd", element: <Details currencyName={"AUDUSD"} /> },
          { path: "nzdusd", element: <Details currencyName={"NZDUSD"} /> },
          { path: "dxy", element: <Details currencyName={"DXY"} /> },
          { path: "usdcad", element: <Details currencyName={"USDCAD"} /> },
          { path: "usdchf", element: <Details currencyName={"USDCHF"} /> },
          { path: "usdjpy", element: <Details currencyName={"USDJPY"} /> },
        ],
      },
    ],
  },
];

export default AppRoute;
