let API_URL = process.env.REACT_APP_API_URL;

if (typeof API_URL === "string" && API_URL.length) {
  API_URL = API_URL.replace(/\/$/, "");
}

export { API_URL };

/**
 * Sockets
 */
export const ENDPOINT_WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST;
export const ENDPOINT_WEBSOCKET_AUTH = `${API_URL}/broadcasting/auth`;

/**
 * User
 */
export const ENDPOINT_LOGIN = `${API_URL}/v1/login`;
export const ENDPOINT_LOGOUT = `${API_URL}/v1/logout`;
export const ENDPOINT_FORGOT = `${API_URL}/v1/users/passwords`;
export const ENDPOINT_USER = `${API_URL}/v1/users`;
export const ENDPOINT_VERIFY = `${API_URL}/v1/account/verify`;
export const ENDPOINT_REFRESH = `${API_URL}/v1/login/refresh`;
export const ENDPOINT_ACCOUNT_VERIFY = `${API_URL}/v1/account/verify/`;
export const ENDPOINT_RESEND_VERIFY = `${API_URL}/v1/account/verify/resend-validation`;
export const ENDPOINT_USER_PROFILE = `${API_URL}/v1/profile`;
export const ENDPOINT_USER_PROFILE_SECURITY = `${API_URL}/v1/profile/security`;
export const ENDPOINT_USER_CURRENCY_SETTINGS = `${API_URL}/v1/currency_settings`;
export const ENDPOINT_NEWS = `${API_URL}/v1/news/latest`;
