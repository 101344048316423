import * as types from "../actions/types";

const initialState = {
  token: {},
  profile: {
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    loading: false,
    currencySettings: [],
  },
  verify: {
    valid: false,
    status: "checking",
  },
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case types.USER_INFO_LOADING:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };
    case types.USER_INFO:
      const { id, first_name, last_name, email, currencySettings } =
        action.payload;
      let loading = state.profile.loading;
      if (id) {
        loading = false;
      }

      return {
        ...state,
        profile: {
          id,
          first_name,
          last_name,
          email,
          loading: loading,
          currencySettings,
        },
      };
    case types.CURRENCY_SETTINGS_UPDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          currencySettings: [...action.payload],
        },
      };
    case types.TOKEN_DETAILS:
      const { access_token, expires_at, refresh_token } = action.payload;
      return {
        ...state,
        token: {
          access_token,
          expires_at,
          refresh_token,
        },
      };
    /* Used for tests */
    case types.VERIFY_TOKEN_IS_VALID:
      const { valid, status } = action.payload;
      return {
        ...state,
        verify: {
          valid,
          status,
        },
      };
    case types.USER_LOGOUT:
      return {
        ...state,
        token: {
          access_token: null,
          expires_at: null,
          refresh_token: null,
        },
      };
    default:
      return state;
  }
};

export default user;
