import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import moment from "moment";
import "moment/locale/en-gb";
import { LOCALES } from "./lang";

function mapStateToProps(state) {
  const { lang } = state.locale;
  moment.locale(lang);
  return { locale: lang, key: lang, messages: LOCALES[lang].messages };
}

export default connect(mapStateToProps)(IntlProvider);
