import React from "react";
import ContentLoader from "react-content-loader";

export default function ProfileLoader() {
  return (
    <ContentLoader
      speed={2}
      width={160}
      height={30}
      viewBox="0 0 160 30"
      backgroundColor="#1f2936"
      foregroundColor="#6d7983"
    >
      <rect x="0" y="7" rx="5" ry="5" width="160" height="16" />
    </ContentLoader>
  );
}
