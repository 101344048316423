Object.defineProperty(Array.prototype, "swapIndexes", {
  value: function (index1, index2) {
    if (index1 === index2) {
      return;
    }
    const temp = this[index1];
    this[index1] = this[index2];
    this[index2] = temp;
  },
});
