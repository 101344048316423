export const getDashboardPivots = (data) => {
  let pivots = [];

  if (data.pivot_r3_highlight) {
    pivots.push("R3");
  }

  if (data.pivot_r2_highlight) {
    pivots.push("R2");
  }

  if (data.pivot_r1_highlight) {
    pivots.push("R1");
  }

  if (data.pivot_c_highlight) {
    pivots.push("CP");
  }

  if (data.pivot_s1_highlight) {
    pivots.push("S1");
  }

  if (data.pivot_s2_highlight) {
    pivots.push("S2");
  }

  if (data.pivot_s3_highlight) {
    pivots.push("S3");
  }

  return pivots;
};

export const getDashboardResistance = (data) => {
  let resistance = [];

  if (data.res_4h_highlight) {
    resistance.push("4H");
  }

  if (data.res_8h_highlight) {
    resistance.push("8H");
  }

  if (data.res_12h_highlight) {
    resistance.push("12H");
  }

  if (data.res_today_highlight) {
    resistance.push("Daily");
  }

  if (data.res_yesterday_highlight) {
    resistance.push("Yesterday");
  }

  if (data.res_weekly_highlight) {
    resistance.push("Weekly");
  }

  if (data.res_monthly_highlight) {
    resistance.push("Monthly");
  }

  return resistance;
};

export const getDashboardSupport = (data) => {
  let support = [];

  if (data.supp_4h_highlight) {
    support.push("4H");
  }

  if (data.supp_8h_highlight) {
    support.push("8H");
  }

  if (data.supp_12h_highlight) {
    support.push("12H");
  }

  if (data.supp_today_highlight) {
    support.push("Daily");
  }

  if (data.supp_yesterday_highlight) {
    support.push("Yesterday");
  }

  if (data.supp_weekly_highlight) {
    support.push("Weekly");
  }

  if (data.supp_monthly_highlight) {
    support.push("Monthly");
  }

  return support;
};

export const getDashboardRSI = (data) => {
  let rsi = [];

  if (data.rsi_5min_highlight) {
    rsi.push("5 min");
  }

  if (data.rsi_15min_highlight) {
    rsi.push("15 min");
  }

  if (data.rsi_30min_highlight) {
    rsi.push("30 min");
  }

  if (data.rsi_1h_highlight) {
    rsi.push("1H");
  }

  if (data.rsi_4h_highlight) {
    rsi.push("4H");
  }

  if (data.rsi_1d_highlight) {
    rsi.push("1D");
  }

  return rsi;
};

export const getHighlightColor = (data) =>
  data.dist_from_high <= 10
    ? "bg-warning"
    : data.dist_from_low <= 10
    ? "bg-green"
    : !data.current_adr_highlight
    ? "bg-yellow"
    : "bg-white";
