import * as types from "./types";

export const socketConnect = (id) => ({
  type: types.SOCKET_CONNECT,
  payload: {
    id,
  },
});

export const socketLogout = () => ({
  type: types.SOCKET_LOGOUT,
});
