import React from "react";

function Footer() {
  return (
    <div id="footer" className="app-footer">
      &copy; 2022 SeanTheme All Right Reserved
    </div>
  );
}

export default Footer;
