import React, { useEffect } from "react";
import { Card, CardBody } from "../../components/card/card";
import Entry from "../../components/pages/currency/Entry";
import { connect } from "react-redux";
import News from "../../components/pages/currency/News";
import { getNews } from "../../api/news";
import { store } from "../../store/store";
import { updateAllNews, updateAllNewsTimeLeft } from "../../store/actions/news";
import {
  DEFAULT_CURRENCY,
  UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS,
} from "../../constants";
import { trimUSD } from "../../api/helper";

function EntryRequirements({ currencies, currencySettings, news }) {
  const enabledCurrencies = currencySettings
    .filter((currency) => currency.enabled)
    .map((settings) => trimUSD(settings.currency.name));

  let filteredNews = news.filter(
    (news) =>
      news.currency_code === DEFAULT_CURRENCY ||
      enabledCurrencies.indexOf(news.currency_code) > -1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      store.dispatch(updateAllNewsTimeLeft());
    }, UPDATE_NEWS_TIME_LEFT_INTERVAL_IN_SECONDS * 1000);

    getNews().then((response) => {
      store.dispatch(updateAllNews(response.data.data));
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="col-xl-12">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small mb-3">
              <span className="flex-grow-1">Entry requirements</span>
            </div>
            <div className="row">
              {currencySettings
                .filter((currency) => currency.enabled)
                .map((settings) => (
                  <div
                    className="col-md-6 col-lg-3"
                    key={settings?.currency?.name}
                  >
                    <Entry
                      title={settings?.currency?.name}
                      data={currencies[settings?.currency?.name]}
                    />
                  </div>
                ))}
            </div>
          </CardBody>
        </Card>
      </div>
      <News data={filteredNews} />
    </div>
  );
}

export default connect((state) => ({
  currencies: state.currencies,
  currencySettings: state.user.profile.currencySettings,
  news: state.news.news,
}))(EntryRequirements);
